import React from 'react'
import { Container, Flex, Box, css } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import ContentContainer from '@solid-ui-components/ContentContainer'
import { ImQuotesRight } from 'react-icons/im'

const TestimonialsBlock01 = ({ content: { text, collection } }) => (
  <Container>
    <Divider />
    <Flex sx={{ mx: -3, flexWrap: `wrap` }}>
      {collection?.map(({ container, avatar, text }, index) => (
        <Box
          key={`item-${index}`}
          sx={{ flexBasis: [`1`], flexGrow: 1, p: 3 }}
        >
          <Reveal effect='fadeInLeft' delay={0.3}>
            <ContentContainer
              content={container}
              variant='cards.paper'
              sx={{ textAlign: `center` }}
            >
              <ImQuotesRight css={css({ size: `icon.xs`, color: `alphaDark` })} />
              <ContentText content={text} />
            </ContentContainer>
          </Reveal>
        </Box>
      ))}
    </Flex>
  </Container>
)

export default TestimonialsBlock01
